import React from 'react';
import { useIntl } from "gatsby-plugin-intl"

export default ({
    className = "",
    shadow = false,
    rounded = false,
    icon = null,
    align = "left",
    image = null,
    imageTitle = null,
    imageSubtitle = null,
    title = null,
    description = null,
    descriptionClassName = "",
    withReadMore = false,
    onReadMore = () => {}
}) => {
    const intl = useIntl()

    return (
        <div className={`card card-align-${align}${shadow ? " card-shadow" : ""}${rounded ? " card-rounded" : ""} ${className}`}>
            {icon && icon()}
            {title && <h1 className="card-title">{title}</h1> }
            {image && <img src={image} alt={imageTitle ? imageTitle : title ? title : "image"} className="card-image" />}
            {imageTitle && <h2 className="card-image-title">{imageTitle}</h2>}
            {imageSubtitle && <span className="card-image-subtitle">{imageSubtitle}</span>}
            {description && <p className={`card-description ${descriptionClassName}`}>{description}</p>}

            {withReadMore && <span
                    className="card-read-more-container"
                    role="button"
                    tabIndex={0}
                    onClick={() => onReadMore()}
                    onKeyDown={event => {
                        if(event.keyCode === 13){
                            onReadMore()
                        }
                    }}
                >
                    {intl.formatMessage({ id: "read-more" })}
                </span>
            }
        </div>
    )
}
