import { useState, useLayoutEffect } from 'react'

export const IsElementOnViewHook = (elementId) => {
    const [isVisible, setIsVisible] = useState(false)

    const isScrolledIntoView = () => {
        const el = document.getElementById(elementId)
        
        if(el){
            var rect = el.getBoundingClientRect();
            var elemTop = rect.top;
            var elemBottom = rect.bottom;
        
            var visible = elemTop < window.innerHeight && elemBottom >= 0;

            setIsVisible(visible)
        } else {
            setIsVisible(false)
        }
    }

    useLayoutEffect(() => {
        function updateScroll() {
            isScrolledIntoView()
        }
        document.addEventListener('scroll', updateScroll);
        updateScroll();
        return () => document.removeEventListener('scroll', updateScroll);
    }, []);

    useLayoutEffect(() => {
        function updateSize() {
            isScrolledIntoView()
        }
        document.addEventListener('resize', updateSize);
        updateSize();
        return () => document.removeEventListener('resize', updateSize);
    }, []);

    return isVisible
}