import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

export default (props) => {
    const {
        children,
        // location
    } = props
    
    return (
        <div className="page-layout">
            <Navbar />

            {children}    

            <Footer />
        </div>
    )
}