export const scrollToElement = (elementId) => {
  var addonOffset = 0
  const screenWidth = window.innerWidth

  var breakpoint = "xs"
  if(screenWidth >= 576) { breakpoint = "sm" }
  if(screenWidth >= 768) { breakpoint = "md"}
  if(screenWidth >= 992) { breakpoint = "lg" }
  if(screenWidth >= 1280 ) { breakpoint = "xl"}

  if(["lg", "xl"].includes(breakpoint)){
    addonOffset += -75
  }

  if(elementId !== "join-us"){
    addonOffset += -10
  }

  const searchingElement = document.getElementById(elementId)
  if(searchingElement){
      window.scrollTo({
          top: searchingElement.offsetTop + addonOffset,
          left: 0,
          behavior: 'smooth'
      })    
  }
}

export const phoneFormatter = (number = "") => {
    var formatted = number;
    const _phone = number.replace(/\s/g, '');
    if (_phone.length > 0) {
      var phoneParts = _phone.match(/.{1,3}/g);
      formatted = phoneParts.join(' ')
    }
  
    return formatted
  };

export const srcFormatter = (src = "") => {
  // console.log(src)

  // if(src && !src.includes("https")){
  //   return `${process.env.GATSBY_BASE_IMAGE_URL}${src}`
  // } else {
  //   return src
  // }
  return src
}