import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import ApplyJobForm from './ApplyJobForm'
import { useIntl } from "gatsby-plugin-intl"

export default ({
    show = false,
    onToggle = () => {},
    recaptchaRef = null
}) => {
    const intl = useIntl()

    return (
        <Modal
            isOpen={show} 
            toggle={() => onToggle()}
            size="md"
            centered={true}
        >
           <ModalBody className="modal-custom-body">
                <div className="modal-custom-header">
                    <h1 className="modal-custom-title">{intl.formatMessage({ id: "home.send-cv-modal-title" })}</h1>
                        
                    <div 
                        className="modal-close-icon" 
                        onClick={() => onToggle()} 
                        role="button"
                        tabIndex={0}
                        onKeyDown={event => {
                            if(event.keyCode === 13){
                                onToggle()
                            }
                        }}
                    />
                </div>

                <ApplyJobForm 
                    recaptchaRef={recaptchaRef}
                    formDescription={intl.formatMessage({ id: "home.send-cv-modal-description" })}
                />
            </ModalBody> 
        </Modal>
    )
}