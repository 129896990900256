import React from "react"
import { Button, Row, Col, Input, Spinner } from "reactstrap"
import Card from '../../components/Card'
import ReCAPTCHA from "react-google-recaptcha";
import CountUp from 'react-countup';
import { CustomCheckbox, FileInput, CustomFormFeedback } from '../../components/CustomInputs'
import { useIntl } from "gatsby-plugin-intl"
import * as validators from '../../utils/validators'
import { scrollToElement } from '../../utils/common.functions'
import { IsElementOnViewHook } from "../../components/isElementOnViewHook";
import JobDescriptionModal from './JobDescriptionModal'
import NotFoundJobModal from './NotFoundJobModal'

// images
import Rocket from "../../assets/images/rocket.webp"
import HomeIllustration from "../../assets/images/home-illustration-2.svg"
import BackgroundWave from "../../assets/images/background-wave.svg"
import ContactDecorLeft from "../../assets/images/contact-decor-left.svg"
import ContactDecorRight from "../../assets/images/contact-decor-right.svg"
import JoinUsDecorLeft from "../../assets/images/join-us-decor-left.svg"
import JoinUsDecorRight from "../../assets/images/join-us-decor-right.svg"
import JakubKanclerz from "../../assets/images/clients/jakub_kanclerz.webp"
import MateuszGorecki from "../../assets/images/clients/mateusz_gorecki.webp"
import NextClient from "../../assets/images/clients/unknown.svg"

export default ({
    initData = {}
}) => {
    const recaptchaRef = React.useRef();

    const {
        stats,
        clients,
        projects,
        jobs
    } = initData

    const [selectedProjectIndex, setSelectedProjectIndex] = React.useState(0)

    const intl = useIntl()

    const isStatsCardVisible = IsElementOnViewHook("stats-card")
    const [statsWasVisible, setStatsWasVisible] = React.useState(false)

    const [showJobDescriptionModal, setShowJobDescriptionModal] = React.useState(false)
    const [selectedJob, setSelectedJob] = React.useState({})
    const [showNotFoundJobModal, setShowNotFoundJobModal] = React.useState(false)

    React.useEffect(() => {
        if(isStatsCardVisible && !statsWasVisible){
            setStatsWasVisible(true)
        }
    }, [isStatsCardVisible])

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")
    const [success, setSuccess] = React.useState(false)
    const [service, setService] = React.useState([])
    const [budget, setBudget] = React.useState(null)
    const [name, setName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [projectDetails, setProjectDetails] = React.useState(null)
    const [approval, setApproval] = React.useState(false)

    const [validation, setValidation] = React.useState({
        serviceStatus: null,
        serviceMessage: "",
        budgetStatus: null,
        budgetMessage: "",
        nameStatus: null,
        nameMessage: "",
        emailStatus: null,
        emailMessage: "",
        projectDetailsStatus: null,
        projectDetailsMessage: "",
        approvalStatus: null,
        approvalMessage: ""
    })

    const onSelectFile = (file) => {
        setProjectDetails(file)
    }

    const validateProperites = (properties) => {
        var _properties = Array.isArray(properties) ? properties : [properties]
        var allValid = true
        var validationResponse = {}
        var newValidation = {}

        if(_properties.includes("service")){
            validationResponse = validators.serviceOptionsValidation(service, intl)
            newValidation = {
                ...newValidation,
                serviceStatus: validationResponse.error ? "error": "success",
                serviceMessage: validationResponse.message
            }

            if(validationResponse.error){
                allValid = false
            }
        }

        if(_properties.includes("budget")){
            validationResponse = validators.budgetOptionsValidation(budget, intl)
            newValidation = {
                ...newValidation,
                budgetStatus: validationResponse.error ? "error": "success",
                budgetMessage: validationResponse.message
            }

            if(validationResponse.error){
                allValid = false
            }
        }

        if(_properties.includes("name")){
            validationResponse = validators.nameValidation(name, intl)
            newValidation = {
                ...newValidation,
                nameStatus: validationResponse.error ? "error": "success",
                nameMessage: validationResponse.message
            }

            if(validationResponse.error){
                allValid = false
            }
        }

        if(_properties.includes("email")){
            validationResponse = validators.emailValidation(email, intl)
            newValidation = {
                ...newValidation,
                emailStatus: validationResponse.error ? "error": "success",
                emailMessage: validationResponse.message
            }

            if(validationResponse.error){
                allValid = false
            }
        }

        if(_properties.includes("projectDetails")){
            validationResponse = validators.projectDetailsValidation(projectDetails, intl)
            newValidation = {
                ...newValidation,
                projectDetailsStatus: validationResponse.error ? "error": "success",
                projectDetailsMessage: validationResponse.message
            }

            if(validationResponse.error){
                allValid = false
            }
        }

        if(_properties.includes("approval")){
            validationResponse = validators.approvalValidation(approval, intl)
            newValidation = {
                ...newValidation,
                approvalStatus: validationResponse.error ? "error": "success",
                approvalMessage: validationResponse.message
            }

            if(validationResponse.error){
                allValid = false
            }
        }

        setValidation({ ...validation, ...newValidation })
        return allValid
    }

    const onSendButton = async () => {
        const allValid = validateProperites(["service", "budget", "name", "email", "projectDetails", "approval"])
        if(allValid){
            const token = await recaptchaRef.current.executeAsync();
            if(token) {
                setLoading(true)
                setSuccess(false)
                setError(false)

                var formData = new FormData();
                formData.append('type', "contact")
                formData.append('service', service)
                formData.append('budget', budget)
                formData.append('name', name)
                formData.append('email', email)
                formData.append('approval', approval)
                formData.append('g-recaptcha-response', token)
                if(projectDetails){
                    formData.append('projectDetails', projectDetails)
                }

                fetch(`${process.env.GATSBY_API_URL}/send.php`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Cache-control': 'no-cache',
                        // 'Access-Control-Allow-Origin': '*'
                    },
                })
                .then(response => response.json())
                .then(result => {
                    if (result && result.error) {
                        setError(true)
                        setSuccess(false)
                        setErrorMessage(result.error)
                    } else {
                        setError(false)
                        setSuccess(true)
                        setService([])
                        setBudget(null)
                        setName("")
                        setEmail("")
                        setProjectDetails(null)
                        setApproval(false)
                    }
                    setLoading(false)
                })
                .catch(error => {
                    setLoading(false)
                    setError(true)
                    setErrorMessage(intl.formatMessage({ id: "home.sending-estimate-error" }))
                    console.error('Error:', error);
                });
            }
        }
    }

    const servicesOptions = [
        {
            label: intl.formatMessage({ id: "services-options.development" }),
            value: "development"
        },
        {
            label: intl.formatMessage({ id: "services-options.interface-design" }),
            value: "interface-design"
        },
        {
            label: intl.formatMessage({ id: "services-options.audit" }),
            value: "audit"
        },
        {
            label: intl.formatMessage({ id: "services-options.api" }),
            value: "api"
        },
        {
            label: intl.formatMessage({ id: "services-options.web-app" }),
            value: "web-app"
        },
        {
            label: intl.formatMessage({ id: "services-options.mobile-app" }),
            value: "mobile-app"
        },
    ]

    const budgetOptions = [
        {
            label: intl.formatMessage({ id: "home.less-than" }).replace("[VALUE]", "20k"),
            value: "0-20000"
        },
        {
            label: "20k - 50k",
            value: "20000-50000"
        },
        {
            label: intl.formatMessage({ id: "home.more-than" }).replace("[VALUE]", "50k"),
            value: "50000+"
        },
    ]

    return (
        <div className="page-content">
            <JobDescriptionModal
                show={showJobDescriptionModal}
                onToggle={() => setShowJobDescriptionModal(!showJobDescriptionModal)}
                selectedJob={selectedJob}
                recaptchaRef={recaptchaRef}
            />

            <NotFoundJobModal
                show={showNotFoundJobModal}
                onToggle={() => setShowNotFoundJobModal(!showNotFoundJobModal)}
                recaptchaRef={recaptchaRef}
            />

            <div className="home-init-section section full-screen-container navbar-padding">
                <Row className="home-init-section-row limited-content">
                    <Col xs={12} md={"auto"} className="home-init-section-texts-container pl-0">
                        <h1 className="home-init-section-title fade-in-left">
                            {intl.formatMessage({ id: "home.init-section-title" })}
                            <img
                                src={Rocket}
                                alt="rocket"
                                className="home-init-section-title-icon"
                            />
                        </h1>

                        <img
                            src={HomeIllustration}
                            alt="home-illustration-2"
                            className="home-init-section-image-mobile"
                        />

                        <p className="home-init-section-description fade-in-left">
                            {intl.formatMessage({ id: "home.init-section-description" })}
                        </p>

                        <div className="home-init-section-button-container fade-in-left">
                            <Button
                                className="home-init-section-button"
                                color="primary"
                                onClick={() => scrollToElement("estimate")}
                            >
                                {intl.formatMessage({ id: "home.init-section-button" })}
                                <i className="icon-wand home-init-section-button-icon" />
                            </Button>
                        </div>
                    </Col>

                    <Col xs={12} md={"auto"} className="d-flex flex-column justify-content-center p-0">
                        <img
                            src={HomeIllustration}
                            alt="home-illustration-2"
                            className="home-init-section-image"
                        />
                    </Col>
                </Row>
                <div className="home-init-section-wave-container">
                    <img
                        src={BackgroundWave}
                        alt="background-wave"
                        className="w-100"
                    />

                    <i
                        className="icon-chevrons-down home-init-section-wave-chevrons"
                        onClick={() => scrollToElement("services")}
                        role="button"
                        tabIndex={0}
                        onKeyDown={event => {
                            if(event.keyCode === 13){
                                scrollToElement("services")
                            }
                        }}
                    />
                </div>
            </div>

            <div className="home-services-section section limited-content" id="services">
                <h2 className="section-title">{intl.formatMessage({ id: "home.services-section-title" })}</h2>

                <Row className="home-services-row">
                    <Col xs={12} lg={4} className="home-services-col">
                        <Card
                            className="home-services-card"
                            shadow={true}
                            rounded={true}
                            icon={() => <i className="icon-web home-services-card-icon" />}
                            title={intl.formatMessage({ id: "home.services-section-web-card-title" })}
                            description={intl.formatMessage({ id: "home.services-section-web-card-description" })}
                            descriptionClassName="home-services-card-description"
                            // withReadMore={true}
                            onReadMore={() => {}}
                        />
                    </Col>

                    <Col xs={12} lg={4} className="home-services-col">
                        <Card
                            className="home-services-card"
                            shadow={true}
                            rounded={true}
                            icon={() => <i className="icon-mobile home-services-card-icon" />}
                            title={intl.formatMessage({ id: "home.services-section-mobile-card-title" })}
                            description={intl.formatMessage({ id: "home.services-section-mobile-card-description" })}
                            descriptionClassName="home-services-card-description"
                            // withReadMore={true}
                            onReadMore={() => {}}
                        />
                    </Col>

                    <Col xs={12} lg={4} className="home-services-col">
                        <Card
                            className="home-services-card"
                            shadow={true}
                            rounded={true}
                            icon={() => <i className="icon-design home-services-card-icon" />}
                            title={intl.formatMessage({ id: "home.services-section-design-card-title" })}
                            description={intl.formatMessage({ id: "home.services-section-design-card-description" })}
                            descriptionClassName="home-services-card-description"
                            // withReadMore={true}
                            onReadMore={() => {}}
                        />
                    </Col>
                </Row>
            </div>

            <div className="home-clients-section section limited-content" id="clients">
                <h2 className="section-title mb-3">{intl.formatMessage({ id: "home.some-our-clients" })}</h2>

                <div className="home-clients-card-logos-container">
                    {Array.isArray(clients) && clients.map((client, k) => {
                        return (
                          <div key={k} className="home-clients-card-logo">
                              <a href={client.url} rel="noindex, nofollow" target="_blank">
                                  <img
                                    src={client.logo}
                                    alt={client.logo}
                                    className="w-100"
                                  />
                              </a>
                          </div>
                        )
                    })}
                </div>

                <Row className="home-clients-row">
                    <Col xs={12} lg={4} className="home-clients-card-col">
                        <Card
                            align={"center"}
                            image={JakubKanclerz}
                            imageTitle={"Jakub Kanclerz"}
                            imageSubtitle={intl.formatMessage({ id: "home.references-first-person" })}
                            description={intl.formatMessage({ id: "home.references-first" })}
                        />
                    </Col>

                    <Col xs={12} lg={4} className="home-clients-card-col">
                        <Card
                            align={"center"}
                            image={MateuszGorecki}
                            imageTitle={"Mateusz Górecki"}
                            imageSubtitle={intl.formatMessage({ id: "home.references-second-person" })}
                            description={intl.formatMessage({ id: "home.references-second" })}
                        />
                    </Col>

                    <Col xs={12} lg={4} className="home-clients-card-col">
                        <Card
                            align={"center"}
                            image={NextClient}
                            imageTitle={intl.formatMessage({ id: "home.references-third-person"})}
                            imageSubtitle={intl.formatMessage({ id: "home.references-third-subtitle"})}
                            description={intl.formatMessage({ id: "home.references-third" })}
                        />
                    </Col>
                </Row>

                <h2 className="section-title mt-5 mb-3">{intl.formatMessage({ id: "home.last-projects" })}</h2>

                <Row className="home-clients-row home-clients-row-mobile-margin">
                    <Col xs={12} lg={4}>
                        <div className="home-stats-card home-case-studies-bottom-left-card" id="stats-card">
                            <div className="home-stats-card-background-image" />
                            <div className="home-stats-card-md-title d-none d-md-block d-lg-none">
                                <h4 className="home-stats-card-title">
                                    {intl.formatMessage({ id: "home.some-our-stats" })}
                                </h4>
                            </div>
                            <div className="home-stats-card-left-container">
                                <h4 className="home-stats-card-title d-md-none d-lg-block">
                                    {intl.formatMessage({ id: "home.some-our-stats" })}
                                </h4>

                                <div className="home-stats-card-stat-container">
                                    <span className="home-stats-big-stats-amount">
                                        {!statsWasVisible
                                            ? Number(stats.uniqueProjectsCompleted)
                                            : <CountUp
                                                start={0}
                                                end={Number(stats.uniqueProjectsCompleted)}
                                                delay={0}
                                                duration={3}
                                            />
                                        }
                                    </span>
                                    <span className="home-stats-big-stats-label">{intl.formatMessage({ id: "home.unique-projects-completed" })}</span>
                                </div>

                                <div className="d-md-none d-lg-block" />
                            </div>

                            <div className="home-stats-card-right-container">
                                <div className="home-stats-card-stat-container home-stats-card-small-stat-container">
                                    <span className="home-stats-small-stats-amount">
                                        {!statsWasVisible
                                            ? Number(stats.satisfiedCustomers)
                                            : <CountUp
                                                start={0}
                                                end={Number(stats.satisfiedCustomers)}
                                                delay={0}
                                                duration={5}
                                            />
                                        }
                                    </span>
                                    <span className="home-stats-small-stats-label">{intl.formatMessage({ id: "home.satisfied-customers" })}</span>
                                </div>

                                <div className="home-stats-card-stat-container home-stats-card-small-stat-container">
                                    <span className="home-stats-small-stats-amount">
                                        {!statsWasVisible
                                            ? Number(stats.yearsExperience)
                                            : <CountUp
                                                start={0}
                                                end={Number(stats.yearsExperience)}
                                                delay={0}
                                                duration={5}
                                            />
                                        }
                                    </span>
                                    <span className="home-stats-small-stats-label">{intl.formatMessage({ id: "home.years-experience" })}</span>
                                </div>

                                <div className="home-stats-card-stat-container home-stats-card-small-stat-container">
                                    <span className="home-stats-small-stats-amount">
                                        {!statsWasVisible
                                            ? Number(stats.linesOfCodeWritten)
                                            : <CountUp
                                                start={0.00}
                                                end={Number(stats.linesOfCodeWritten)}
                                                delay={0}
                                                duration={5}
                                                decimals={2}
                                            />
                                        }M
                                    </span>
                                    <span className="home-stats-small-stats-label">{intl.formatMessage({ id: "home.lines-of-code-written" })}</span>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} lg={8} className="d-none d-lg-block">
                        <div className="home-case-studies-card home-case-studies-bottom-right-card">
                            {projects[selectedProjectIndex] &&
                                <>
                                    <a href={projects[selectedProjectIndex].url} className="home-stats-card-background-image" rel="noindex, nofollow" target="_blank">
                                        <img
                                            src={projects[selectedProjectIndex].image}
                                            alt={projects[selectedProjectIndex].image.replace("/", "")}
                                            className="home-case-studies-card-image"
                                        />

                                        <div className="home-case-studies-card-content">
                                        <div className="d-flex flex-row w-100 h-100 align-items-center justify-content-between">
                                            {selectedProjectIndex > 0 && projects.length > 1
                                                ? <div
                                                    className="home-case-studies-card-previous-container"
                                                    onClick={() => setSelectedProjectIndex(selectedProjectIndex - 1)}
                                                    role="button"
                                                    tabIndex={0}
                                                    onKeyDown={event => {
                                                        if(event.keyCode === 13){
                                                            setSelectedProjectIndex(selectedProjectIndex - 1)
                                                        }
                                                    }}
                                                />
                                                : <div />
                                            }

                                            {projects.length > 1 && selectedProjectIndex !== projects.length - 1
                                                ? <div
                                                    className="home-case-studies-card-next-container"
                                                    onClick={() => setSelectedProjectIndex(selectedProjectIndex + 1)}
                                                    role="button"
                                                    tabIndex={0}
                                                    onKeyDown={event => {
                                                        if(event.keyCode === 13){
                                                            setSelectedProjectIndex(selectedProjectIndex + 1)
                                                        }
                                                    }}
                                                />
                                                : <div />
                                            }
                                        </div>

                                        <div className="home-case-studies-card-info-container">
                                            <div className="home-case-studies-card-title-row">
                                                <h4 className="home-case-studies-card-title">
                                                    {projects[selectedProjectIndex].name} <span className="home-case-studies-card-title-break">|</span>
                                                </h4>
                                                <span className="home-case-studies-card-tags">{projects[selectedProjectIndex].tags.join(", ")} </span>
                                            </div>

                                            <div className="home-case-studies-card-technologies-row">
                                                {projects[selectedProjectIndex].technologies.map((technology, k) => {
                                                    return (
                                                        <span
                                                            key={k}
                                                            className="home-case-studies-card-technology-badge"
                                                        >{technology}</span>
                                                    )
                                                })}

                                                {/* <span className="home-case-studies-card-case-study">{intl.formatMessage({ id: "home.case-study" })}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="home-collaborating-card clickable" onClick={() => scrollToElement("estimate")}>
                <img
                    src={ContactDecorLeft}
                    alt="contact-decor-left"
                    className="home-collaborating-card-left-decoration"
                />

                <span className="home-collaborating-card-text">
                    {intl.formatMessage({ id: "home.interested-in-collaborating" })}
                </span>

                <img
                  src={ContactDecorRight}
                    alt="contact-decor-right"
                    className="home-collaborating-card-right-decoration"
                />
            </div>

            <div className="home-join-us-section section" id="join-us">
                <div className="home-join-us-container limited-content">
                    <div className="home-join-us-section-title-container">
                        <h2 className="section-title home-join-us-section-title">{intl.formatMessage({ id: "home.join-us-section-title" })}</h2>

                        <p className="section-description home-join-us-section-description">{intl.formatMessage({ id: "home.join-us-section-description" })}</p>
                    </div>

                    <div className="home-join-us-buttons-container">
                        {jobs.map((job, k) => {
                            return (
                                <Button
                                    key={k}
                                    color="white"
                                    className="home-join-us-button"
                                    onClick={() => {
                                        setSelectedJob(job)
                                        setShowJobDescriptionModal(true)
                                    }}
                                >
                                    {job.title}
                                    <i className="icon-arrow-right home-join-us-button-icon" />
                                </Button>
                            )
                        })}

                        <Button
                            color="primary"
                            className="home-join-us-button home-join-us-havent-found-button"
                            onClick={() => setShowNotFoundJobModal(true)}
                        >
                            {intl.formatMessage({ id: "home.havent-found-what-you-are-looking-for" })}
                            <i className="icon-arrow-right home-join-us-button-icon" />
                        </Button>
                    </div>
                </div>

                <img
                    src={JoinUsDecorLeft}
                    alt="join-us-decor-left"
                    className="home-join-us-left-decoration"
                />

                <div className="home-join-us-illustration" />

                <img
                    src={JoinUsDecorRight}
                    alt="join-us-decor-right"
                    className="home-join-us-right-decoration"
                />
            </div>

            <Row className="w-100 limited-content ml-auto mr-auto home-contacts-row">
                <Col xs={12} lg={"auto"} className="home-contact-section p-0">
                    <h2 className="section-title home-contact-section-title">{intl.formatMessage({ id: "home.contact-section-title" })}</h2>
                    <p className="section-description home-contact-section-description">{intl.formatMessage({ id: "home.contact-section-description" })}</p>

                    <div className="home-contacts-container">
                        <h3 className="home-contacts-subsection-title">
                            {intl.formatMessage({ id: "home.contacts" })}
                        </h3>

                        <span className="home-contact mb-5">{process.env.GATSBY_CONTACT_EMAIL}</span>

                        <p><strong>{intl.formatMessage({ id: "home.company-data" })}</strong>:</p>

                        <p>
                            Codein sp. z o.o. <br />
                            ul. Św. Marcin 29/8,<br />
                            61-806 Poznań,<br />
                            KRS: 0000916116<br />
                            NIP: 7831841384
                        </p>

                        {/*<span className="home-contact">{phoneFormatter(process.env.GATSBY_CONTACT_PHONE)}</span>*/}
                    </div>

                    {/* <div className="home-follow-container">
                        <h3 className="home-contacts-subsection-title">
                            {intl.formatMessage({ id: "home.follow" })}:
                        </h3>

                        <div className="home-follow-icons-container">
                            <i className="icon-in home-follow-icon" />
                            <i className="icon-ig home-follow-icon" />
                            <i className="icon-gb home-follow-icon" />
                            <i className="icon-dribbble home-follow-icon" />
                            <i className="icon-be home-follow-icon" />
                        </div>
                    </div> */}

                    <div className="home-footer-links-container home-footer-links-large">
                        <span className="home-footer-link">&copy; Codein {process.env.GATSBY_COPYRIGHTS_YEAR}</span>
                        <a href={`/${intl.locale}/privacy_policy.pdf`} target="_blank" className="home-footer-link">{intl.formatMessage({ id: "privacy-policy" })}</a>
                        <a href={`/${intl.locale}/cookies.pdf`} target="_blank" className="home-footer-link">{intl.formatMessage({ id: "cookies" })}</a>
                    </div>
                </Col>

                <Col xs={"auto"} className="home-estimate-section p-0" id="estimate">
                    <h2 className="section-title">{intl.formatMessage({ id: "home.estimate-section-title" })}</h2>

                    <div className="home-picker-container">
                        <h4 className="home-contacts-subsection-title home-estimate-services-title">
                            {intl.formatMessage({ id: "home.services-section-title" })}
                        </h4>

                        <div className="home-picker-buttons-container">
                            {servicesOptions.map((option, k) => {
                                return (
                                    <Button
                                        key={k}
                                        color="primary-light"
                                        active={service.includes(option.value)}
                                        className={`btn home-picker-button`}
                                        onClick={() => {
                                            if(service.includes(option.value)){
                                                setService(service.filter(e => e !== option.value))
                                            } else {
                                                setService([...service, option.value])
                                            }
                                        }}
                                    >{option.label}</Button>
                                )
                            })}
                        </div>

                        {validation.serviceStatus === "error" && <span className="form-validation-text validation-error">{validation.serviceMessage}</span>}
                    </div>

                    <div className="home-picker-container">
                        <h4 className="home-contacts-subsection-title">{intl.formatMessage({ id: "home.budget-in-currency" }).replace("[CURRENCY]", "PLN")}</h4>
                        <div className="home-picker-buttons-container">
                            {budgetOptions.map((option, k) => {
                                return (
                                    <Button
                                        key={k}
                                        color="primary-light"
                                        active={option.value === budget}
                                        className={`btn home-picker-button`}
                                        onClick={() => setBudget(option.value)}
                                    >{option.label}</Button>
                                )
                            })}
                        </div>

                        {validation.budgetStatus === "error" && <span className="form-validation-text validation-error">{validation.budgetMessage}</span>}
                    </div>

                    <form className="w-100 home-estimate-form">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={process.env.GATSBY_RECAPTCHA_CODE}
                        />

                        <Row>
                            <Col xs={12} lg={6}>
                                <div className="form-group">
                                    <Input
                                        className="bottom-bordered-input"
                                        placeholder={intl.formatMessage({ id: "home.name-placeholder" })}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        id="home-name-input"
                                    />

                                    {validation.nameStatus === "error" && <span className="form-validation-text validation-error">{validation.nameMessage}</span>}
                                </div>
                            </Col>

                            <Col xs={12} lg={6}>
                                <div className="form-group">
                                    <Input
                                        className="bottom-bordered-input"
                                        placeholder={intl.formatMessage({ id: "home.email-placeholder" })}
                                        value={email}
                                        type="email"
                                        onChange={e => setEmail(e.target.value)}
                                    />

                                    {validation.emailStatus === "error" && <span className="form-validation-text validation-error">{validation.emailMessage}</span>}
                                </div>
                            </Col>

                            <Col xs={12}>
                                <div className="form-group">
                                    <FileInput
                                        className="mb-0"
                                        file={projectDetails}
                                        placeholder={intl.formatMessage({ id: "home.details-placeholder" })}
                                        onDeleteFile={() => setProjectDetails(null)}
                                        onSelectFile={(file) => onSelectFile(file)}
                                    />

                                    {/* <div className="home-estimate-details-input-container">
                                        <Input
                                            className={`bottom-bordered-input ${projectDetails ? "home-estimate-details-input-with-delete " : "home-estmiate-details-input "}disabled-button-without-styling mb-0 text-truncate`}
                                            disabled
                                            placeholder={projectDetails
                                                ? projectDetails.name
                                                : intl.formatMessage({ id: "home.details-placeholder" })
                                            }
                                        />

                                        {projectDetails &&
                                            <div className="file-input-delete-cross-container" onClick={() => setProjectDetails(null)}>
                                                <div className="file-input-delete-cross">
                                                    <div className="file-input-delete-cross-1" />
                                                    <div className="file-input-delete-cross-2" />
                                                </div>
                                            </div>
                                        }

                                        <i
                                            className="icon-attachment home-estimate-details-input-icon"
                                            onClick={() => fileInputRef.current.click()}
                                        />
                                    </div> */}

                                    {validation.projectDetailsStatus === "error" && <span className="form-validation-text validation-error">{validation.projectDetailsMessage}</span>}
                                </div>
                            </Col>

                            <Col className="home-estimate-send-row-container" xs={12}>
                                {success &&
                                    <CustomFormFeedback
                                        className="mb-3"
                                        text={intl.formatMessage({ id: "home.sending-estimate-success" })}
                                        color="success"
                                    />
                                }

                                {error &&
                                    <CustomFormFeedback
                                        className="mb-3"
                                        text={errorMessage}
                                        color="error"
                                    />
                                }

                                <div className="home-estimate-send-row">
                                    <div className="form-group checkbox-group home-estimate-approval-container mb-0">
                                        <CustomCheckbox
                                            id="estimate-checkbox"
                                            checked={approval}
                                            onChange={() => setApproval(!approval)}
                                            className={`form-checkbox checkbox-border`}
                                        />

                                        <span className="form-checkbox-label home-estimate-approval-text">
                                            {intl.formatMessage({ id: "home.approval" })}
                                            {validation.approvalStatus === "error" &&
                                                <>
                                                    <br />
                                                    <span className="form-validation-error validation-error">{validation.approvalMessage}</span>
                                                </>
                                            }
                                        </span>
                                    </div>

                                    <Button
                                        color="secondary"
                                        className="home-estimate-send-button"
                                        onClick={() => onSendButton()}
                                        disabled={loading}
                                    >
                                        {intl.formatMessage({ id: "home.send" })}
                                        {loading && <Spinner size="sm" color="white" className="ml-2" /> }
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>

                    <div className="home-footer-links-container home-footer-links-mobile">
                        <span className="home-footer-link">@ codein {process.env.GATSBY_COPYRIGHTS_YEAR}</span>

                        <a
                            href={`/${intl.locale}/privacy_policy.pdf`}
                            target="_blank" rel="noreferrer"
                            className="home-footer-link"
                            role="navigation"
                            tabIndex={0}
                        >{intl.formatMessage({ id: "privacy-policy" })}</a>

                        <a
                            href={`/${intl.locale}/cookies.pdf`}
                            target="_blank" rel="noreferrer"
                            className="home-footer-link"
                            role="navigation"
                            tabIndex={0}
                        >{intl.formatMessage({ id: "cookies" })}</a>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

// IMAGE EXAMPLE
// const data = useStaticQuery(graphql`
    //     query NavbarQuery {
    //         image: file(relativePath: { eq: "image.jpeg" }) {
    //             childImageSharp {
    //                 # Specify the image processing specifications right in the query.
    //                 # Makes it trivial to update as your page's design changes.
    //                 fluid(maxWidth: 120) {
    //                     ...GatsbyImageSharpFluid
    //                 }
    //             }
    //         }
    //     }
    // `)

    // console.log(data)

    // export const xD = () => {
    //     return (
    //         {/* <div className="home-articles-section section">
    //             <h2 className="section-title">{intl.formatMessage({ id: "home.articles-section-title" })}</h2>

    //             <div className="articles-container">
    //                 <Article
    //                     date={"2020-05-01"}
    //                     image={""}
    //                     title={"Lorem ipsum dolor sit amet"}
    //                     content={"Lorem ipsum dolor sit amet"}
    //                     withReadMore={true}
    //                     onReadMore={() => {}}
    //                 />

    //                 <Article
    //                     date={"2020-05-01"}
    //                     image={""}
    //                     title={"Lorem ipsum dolor sit amet"}
    //                     content={"Lorem ipsum dolor sit amet"}
    //                     withReadMore={true}
    //                     onReadMore={() => {}}
    //                 />

    //                 <Article
    //                     date={"2020-05-01"}
    //                     image={""}
    //                     title={"Lorem ipsum dolor sit amet"}
    //                     content={"Lorem ipsum dolor sit amet"}
    //                     withReadMore={true}
    //                     onReadMore={() => {}}
    //                 />
    //             </div>
    //         </div> */}
    //     )
    // }
