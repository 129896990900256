import React from "react"
import HomeView from '../views/Home/'
import Layout from '../components/Layout'
import routes from "../globals/routes"
import {Helmet} from "react-helmet"

import Logo from "../assets/images/logo.svg"
import {useIntl} from "gatsby-plugin-intl";
// import { graphql, useStaticQuery } from "gatsby"

export default function Home(props) {
    // const initData = useStaticQuery(graphql`
    //     query {
    //         initData {
    //             initData
    //         }
    //     }
    // `)

    const intl = useIntl()
    const [initData, setInitData] = React.useState({
        stats: {
            linesOfCodeWritten: 0,
            yearsExperience: 0,
            satisfiedCustomers: 0,
            uniqueProjectsCompleted: 0
        },
        clients: [],
        projects: [],
        jobs: []
    })

    const getInitData = () => {
        Promise.all([
            fetch("https://codein.pl/stats.json").then(response => response.json()),
            fetch("https://codein.pl/clients.json").then(response => response.json()),
            fetch("https://codein.pl/projects.json").then(response => response.json()),
            fetch("https://codein.pl/jobs.json").then(response => response.json())
        ]).then(responsesArray => {
            setInitData({
                stats: responsesArray[0],
                clients: responsesArray[1],
                projects: responsesArray[2],
                jobs: responsesArray[3]
            })
        })
    }

    React.useEffect(() => {
        getInitData()
    }, [])

    const seo = {
        url: "https://codein.pl/" + intl.locale + "/",
        title: "Codein.pl",
        image: "https://codein.pl/landing.webp?v2"
    }

    return (
      <Layout location={routes.home}>
          <Helmet>
              <meta charSet="utf-8"/>
              <title>Codein.pl - Software in the house</title>
              <link rel="canonical" href={seo.url} />
              <meta property="og:url" content={seo.url} />
              <meta property="og:title" content={seo.title} />
              <meta property="og:image" content={seo.image} />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={seo.title} />
              <meta name="twitter:image" content={seo.image} />
              <meta property="og:description" content={intl.formatMessage({id: "home.init-section-description"})} />
              <meta name="description" content={intl.formatMessage({id: "home.init-section-description"})} />
              <link rel="icon" type="image/png" href="/apple-touch-icon.png" />
              <script type="application/ld+json">
                  {`{
                      "@context": "https://schema.org",
                      "@type": "Organization",
                      "url": "${seo.url}",
                      "name": "Codein",
                      "contactPoint": {
                        "@type": "ContactPoint",
                        "email": "hello@codein.pl",
                        "contactType": "Customer Support"
                      }
                    }
                  `}
              </script>
          </Helmet>
          <HomeView
            {...props}
            // initData={JSON.parse(initData.initData.initData)}
            initData={initData}
            location={routes.home}
          />
      </Layout>
    )
}
