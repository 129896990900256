import React from 'react'
import { Link } from "gatsby-plugin-intl"
import { slide as Menu } from 'react-burger-menu'

const NavbarLink = ({
    label = "",
    customRender = null,
    linkTo = null,
    href = null,
    onClick = () => {},
    onToggle = () => {}
}) => {
    if(customRender){
        return customRender()
    }
    if(linkTo){
        return (
            <Link
                className="mobile-navbar-link"
                to={linkTo}
            >{label}</Link>
        )
    }
    if(href){
        return (
            <a
                className="mobile-navbar-link"
                href={href}
            >{label}</a>
        )
    }
    return (
        <span
            className="mobile-navbar-link"
            onClick={() => {
                onToggle()
                onClick()
            }}
            role="button"
            tabIndex={0}
            onKeyDown={event => {
                if(event.keyCode === 13){
                    onToggle()
                    onClick()
                }
            }}
        >
            {label}
        </span>
    )
}

export default ({
    show = false,
    onToggle = () => {},
    navbarLinks = [],
    intl = { formatMessage: () => "no text function passed" }
}) => {
    return (
        <Menu
            right
            width={"200px"}
            isOpen={show}
            onClose={() => onToggle()}
            menuClassName="mobile-navbar-container"
            overlayClassName="mobile-navbar-overlay"
            itemListClassName="mobile-navbar-content"
            burgerButtonClassName="d-none"
        >
            <div
                className="mobile-navbar-cross"
                onClick={() => onToggle()}
                role="button"
                tabIndex={0}
                onKeyDown={event => {
                    if(event.keyCode === 13){
                        onToggle()
                    }
                }}
            >
                <div className="mobile-navbar-cross-1" />
                <div className="mobile-navbar-cross-2" />
            </div>

            <div className="navbar-our-status-container d-sm-none mb-2">
                <span className="navbar-our-status-title mobile-navbar-our-status-title">
                    {intl.formatMessage({ id: "our-current-status" })}:
                </span>

                <div className="navbar-our-status-statuses-container">
                    {/*<span className="navbar-our-status-badge navbar-our-status-warning">*/}
                    {/*    {intl.formatMessage({ id: "busy" })}*/}
                    {/*</span>*/}
                    <span className="navbar-our-status-badge navbar-our-status-success">
                        {intl.formatMessage({ id: "recruitment" })}
                    </span>
                </div>
            </div>
            <div className="mobile-navbar-links-container">
                {navbarLinks.filter(e => !e.customRender).map((navbarLink, k) => {
                    return (
                        <NavbarLink
                            {...navbarLink}
                            key={k}
                            onToggle={onToggle}
                        />
                    )
                })}
            </div>
            <div className="mobile-navbar-buttons-container">
                {navbarLinks.filter(e => e.customRender).map((navbarLink, k) => {
                    return (
                        <NavbarLink
                            {...navbarLink}
                            key={k}
                            onToggle={onToggle}
                        />
                    )
                })}
            </div>
        </Menu>
    )
}
