export const serviceOptionsValidation = (service = [], intl) => {
    if(service.length === 0){
        return {
            error: true,
            message: intl.formatMessage({ id: "validation.you-have-to-pick-at-least-one-option" })
        }
    }

    return {
        error: false,
        message: ""
    }
}

export const budgetOptionsValidation = (budget = null, intl) => {
    if(!budget || typeof budget !== "string"){
        return {
            error: true,
            message: intl.formatMessage({ id: "validation.you-have-to-pick-option" })
        }
    }

    return {
        error: false,
        message: ""
    }
}

export const nameValidation = (name = "", intl) => {
    if(name.length === 0){
        return {
            error: true,
            message: intl.formatMessage({ id: "validation.cannot-be-empty" })
        }
    }

    if(name.length <= 3){
        return {
            error: true,
            message: intl.formatMessage({ id: "validation.name-must-at-least-amount-characters" }).replace("[AMOUNT]", "3")
        }
    }

    if(name.length > 50){
        return {
            error: true,
            message: intl.formatMessage({ id: "validation.name-must-have-no-more-than-amount-characters" }).replace("[AMOUNT]", "50")
        }
    }

    return {
        error: false,
        message: ""
    }
}

export const emailValidation = (email = "", intl) => {
    if(email.length === 0){
        return {
            error: true,
            message: intl.formatMessage({ id: "validation.cannot-be-empty" })
        }
    }

    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!emailRegex.test(email)) {
        return {
            error: true,
            message: intl.formatMessage({ id: "validation.email-is-invalid" })
        }
	}


    return {
        error: false,
        message: ""
    }
}

export const projectDetailsValidation = (projectDetails = null, intl) => {
    return {
        error: false,
        message: ""
    }
}

export const approvalValidation = (approval = false, intl) => {
    if(approval === false){
        return {
            error: true,
            message: intl.formatMessage({ id: "validation.approval-is-required" })
        }
    }

    return {
        error: false,
        message: ""
    }
}

export const cvValidation = (cv = null, intl) => {
    if([undefined, null].includes(cv)){
        return {
            error: true,
            message: intl.formatMessage({ id: "validation.cv-is-required" })
        }
    }

    return {
        error: false,
        message: ""
    }
}

export const cvDescriptionValidation = (description = "", intl) => {
    if(description.length < 10){
        return {
            error: true,
            message: intl.formatMessage({ id: "validation.description-must-have-at-least-amount-characters" }).replace("[AMOUNT]", "10")
        }
    }

    return {
        error: false,
        message: ""
    }
}