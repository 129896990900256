import React from 'react'

export const CustomCheckbox = ({
    id = "id",
    checked,
    onChange = () => {},
    disabled = false,
    className = ""
}) => {
    return (
        <label htmlFor={id} className={`checkbox-container ${className}`}>
            <input
                type="checkbox"
                checked={checked}
                onChange={() => onChange(checked)}
                disabled={disabled}
                id={id}
                tabIndex={-1}
            />
            <span
                className="checkmark"
                role="button"
                tabIndex={0}
                onKeyDown={event => {
                    if(event.keyCode === 13){
                        onChange(checked)
                    }
                }}
            ></span>
        </label>
    )
}

export const FileInput = ({
    file = null,
    placeholder = "",
    className = "",
    onDeleteFile = () => {},
    onSelectFile = () => {}
}) => {
    var fileInputRef = React.useRef();

    return (
        <div className="file-input-container form-control">
            <input
                type="file"
                onChange={e => {
                    onSelectFile(e.target.files[0])
                    e.target.value = ""
                }}
                accept={".doc,.docx,.pdf"}
                ref={fileInputRef}
                className="d-none"
            />

            <span
                title={file ? file.name : undefined}
                className={`file-input-text ${className}${file ? "" : " file-input-text-muted"}`}
            >
                {file ? file.name : placeholder}
            </span>

            <div className="file-input-icons-container">
                {file &&
                    <div
                        className="file-input-delete-cross-container"
                        onClick={() => onDeleteFile()}
                        role="button"
                        tabIndex={0}
                        onKeyDown={event => {
                            if(event.keyCode === 13){
                                onDeleteFile()
                            }
                        }}
                    >
                        <div className="file-input-delete-cross">
                            <div className="file-input-delete-cross-1" />
                            <div className="file-input-delete-cross-2" />
                        </div>
                    </div>
                }

                <i
                    className="icon-attachment file-input-add-icon"
                    onClick={() => fileInputRef.current.click()}
                    role="button"
                    tabIndex={0}
                    onKeyDown={event => {
                        if(event.keyCode === 13){
                            fileInputRef.current.click()
                        }
                    }}
                />
            </div>
        </div>
    )
}

export const CustomFormFeedback = ({
    className = "",
    text = "",
    color = "success",
}) => {
    return (
        <div className={`custom-form-feedback-container custom-form-feedback-${color} ${className}`}>
            {text}
        </div>
    )
}
