import React from 'react'
import { Button } from 'reactstrap'
import { useIntl, Link } from "gatsby-plugin-intl"
import { scrollToElement } from '../utils/common.functions'
import MobileNavbar from './MobileNavbar'

import Logo from "../assets/images/logo.svg"

const getNavbarLinks = (intl, onHideMobileNavbar = () => {}) => [
    {
        label: intl.formatMessage({ id: "services" }),
        onClick: () => scrollToElement("services")
    },
    {
        label: intl.formatMessage({ id: "clients" }),
        onClick: () => scrollToElement("clients")
    },
    // {
    //     label: intl.formatMessage({ id: "blog" }),
    //     onClick: () => scrollToElement("services")
    // },
    {
        label: intl.formatMessage({ id: "careers" }),
        onClick: () => scrollToElement("join-us")
    },
    {
        customRender: () => (
            <Button
                color="primary"
                outline
                className="get-an-estimate-button"
                onClick={() => {
                    onHideMobileNavbar()
                    scrollToElement("estimate")
                }}
            >{intl.formatMessage({ id: "get-an-estimate" })}</Button>
        )
    }
]

const NavbarLink = ({
    label = "",
    customRender = null,
    linkTo = null,
    href = null,
    onClick = () => {}
}) => {
    if(customRender){
        return customRender()
    }
    if(linkTo){
        return (
            <Link
                className="navbar-link"
                to={linkTo}
            >{label}</Link>
        )
    }
    if(href){
        return (
            <a
                className="navbar-link"
                href={href}
            >{label}</a>
        )
    }
    return (
        <span
            className="navbar-link"
            onClick={() => onClick()}
            role="button"
            tabIndex={0}
            onKeyDown={event => {
                if(event.keyCode === 13){
                    onClick()
                }
            }}
        >
            {label}
        </span>
    )
}

export default (props) => {
    const [mounted, setMounted] = React.useState(false)

    React.useEffect(() => {
        setMounted(true)
    }, [])

    if(mounted){
        return <NavbarComponent {...props} />
    }
    return null
}

const NavbarComponent = ({

}) => {
    const [mobileNavbarToggled, setMobileNavbarToggled] = React.useState(false)
    const [showStickyNav, setShowStickyNav] = React.useState(false);
    const [windowSize, setWindowSize] = React.useState("xl")

    const intl = useIntl()
    const navbarLinks = getNavbarLinks(intl, () => setMobileNavbarToggled(false))

    const onLogoPress = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }

    React.useLayoutEffect(() => {
        function updateSize() {
            if(window.innerWidth >= 1280){
                if(windowSize !== "xl"){
                    setWindowSize("xl")
                }
            } else if (window.innerWidth >= 992) {
                if(windowSize !== "lg"){
                    setWindowSize("lg")
                }
            } else {
                setWindowSize("mobile")
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    React.useLayoutEffect(() => {
        function updateScroll() {
            const visibilityBorder = windowSize === "lg" ? 75 : 75
            if(document.scrollingElement.scrollTop >= visibilityBorder){
                setShowStickyNav(true)
            } else {
                setShowStickyNav(false)
            }
        }
        document.addEventListener('scroll', updateScroll);
        updateScroll();
        return () => document.removeEventListener('scroll', updateScroll);
    }, []);

    React.useEffect(() => {
        if(windowSize === "lg"){
            if(document.scrollingElement.scrollTop > 75){
                if(!showStickyNav){
                    setShowStickyNav(true)
                }
            } else {
                if(showStickyNav){
                    setShowStickyNav(false)
                }
            }
        } else if(windowSize === "xl") {
            if(document.scrollingElement.scrollTop > 75){
                if(!showStickyNav){
                    setShowStickyNav(true)
                }
            } else {
                if(showStickyNav){
                    setShowStickyNav(false)
                }
            }
        }
    }, [windowSize])

    return (
        <>
            <MobileNavbar
                show={mobileNavbarToggled}
                onToggle={() => setMobileNavbarToggled(!mobileNavbarToggled)}
                navbarLinks={navbarLinks}
                intl={intl}
            />

            <div className={`navbar-sticky-container${showStickyNav && windowSize !== "mobile" ? "" : " d-none"}`}>
                <div className="navbar">
                    <div className="navbar-left-container">
                        <img
                            onClick={() => onLogoPress()}
                            src={Logo}
                            alt="logo"
                            role="button"
                            tabIndex={0}
                            onKeyDown={event => {
                                if(event.keyCode === 13){
                                    onLogoPress()
                                }
                            }}
                        />

                        <div className="navbar-our-status-container">
                            <span className="navbar-our-status-title">{intl.formatMessage({ id: "our-current-status" })}:</span>

                            <div className="navbar-our-status-statuses-container">
                                {/*<span className="navbar-our-status-badge navbar-our-status-warning">*/}
                                {/*    {intl.formatMessage({ id: "busy" })}*/}
                                {/*</span>*/}
                                <span className="navbar-our-status-badge navbar-our-status-success">
                                    {intl.formatMessage({ id: "recruitment" })}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="navbar-right-container">
                        <div className="navbar-links-container">
                            {navbarLinks.map((navbarLink, k) => {
                                return (
                                    <NavbarLink
                                        {...navbarLink}
                                        key={k}
                                    />
                                )
                            })}
                        </div>

                        <i
                            className="icon-hamburger navbar-mobile-menu-toggler"
                            onClick={() => setMobileNavbarToggled(!mobileNavbarToggled)}
                            role="button"
                            tabIndex={0}
                            onKeyDown={event => {
                                if(event.keyCode === 13){
                                    setMobileNavbarToggled(!mobileNavbarToggled)
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="navbar-container">
                <div className="navbar">
                    <div className="navbar-left-container">
                        <img
                            onClick={() => onLogoPress()}
                            src={Logo}
                            alt="logo"
                            role="button"
                            tabIndex={0}
                            onKeyDown={event => {
                                if(event.keyCode === 13){
                                    onLogoPress()
                                }
                            }}
                        />

                        <div className="navbar-our-status-container">
                            <span className="navbar-our-status-title">{intl.formatMessage({ id: "our-current-status" })}:</span>

                            <div className="navbar-our-status-statuses-container">
                                {/*<span className="navbar-our-status-badge navbar-our-status-warning">*/}
                                {/*    {intl.formatMessage({ id: "busy" })}*/}
                                {/*</span>*/}
                                <span className="navbar-our-status-badge navbar-our-status-success">
                                    {intl.formatMessage({ id: "recruitment" })}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="navbar-right-container">
                        <div className="navbar-links-container">
                            {navbarLinks.map((navbarLink, k) => {
                                return (
                                    <NavbarLink
                                        {...navbarLink}
                                        key={k}
                                    />
                                )
                            })}
                        </div>

                        <i
                            className="icon-hamburger navbar-mobile-menu-toggler"
                            onClick={() => setMobileNavbarToggled(!mobileNavbarToggled)}
                            role="button"
                            tabIndex={0}
                            onKeyDown={event => {
                                if(event.keyCode === 13){
                                    setMobileNavbarToggled(!mobileNavbarToggled)
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
