import React from 'react'
import { Button, Modal, ModalBody, Row, Col } from 'reactstrap'
import ApplyJobForm from './ApplyJobForm'
import { useIntl } from "gatsby-plugin-intl"

export default ({
    show = false,
    onToggle = () => {},
    selectedJob = {},
    recaptchaRef = null
}) => {
    const intl = useIntl()
    return (
        <Modal
            isOpen={show}
            toggle={() => onToggle()}
            size="xl"
            centered={true}
        >
            <ModalBody className="modal-custom-body">
                <div className="modal-custom-header mb-3">
                    <h1 className="modal-custom-title">{selectedJob.title}</h1>

                    <div
                        className="modal-close-icon"
                        onClick={() => onToggle()}
                        role="button"
                        tabIndex={0}
                        onKeyDown={event => {
                            if(event.keyCode === 13){
                                onToggle()
                            }
                        }}
                    />
                </div>

                <Row className="home-job-apply-modal-row">
                    <Col xs={12} lg={"auto"} className="home-job-apply-modal-description-col">
                        <p className="modal-custom-description">
                            {selectedJob.description}
                        </p>

                        {selectedJob.place &&
                            <div>
                                <strong>{intl.formatMessage({id: "job-modal.place"})}: </strong>
                                <span>{selectedJob.place}</span>
                            </div>
                        }

                        {selectedJob.agreement &&
                            <div>
                                <strong>{intl.formatMessage({id: "job-modal.agreement"})}: </strong>
                                <span>{selectedJob.agreement.join(", ")}</span>
                            </div>
                        }

                        {selectedJob.salary &&
                            <div className="mb-3">
                                <strong>{intl.formatMessage({id: "job-modal.salary"})}: </strong>
                                <span>{selectedJob.salary}</span>
                            </div>
                        }

                        {selectedJob.requirements && selectedJob.requirements.length &&
                            <div>
                                <strong>{intl.formatMessage({ id: "job-modal.requirements" })}</strong>
                                <ul>
                                    {selectedJob.requirements.map((r, idx) => <li key={idx}>
                                        {r}
                                    </li>)}
                                </ul>
                            </div>
                        }

                        {selectedJob.niceToHave && selectedJob.niceToHave.length &&
                            <div>
                                <strong>{intl.formatMessage({ id: "job-modal.nice-to-have" })}</strong>
                                <ul>
                                    {selectedJob.niceToHave.map((r, idx) => <li key={idx}>
                                        {r}
                                    </li>)}
                                </ul>
                            </div>
                        }
                    </Col>

                    <Col xs={12} lg={"auto"} className="home-job-apply-modal-form-col">
                        <h2 className="modal-section-title">
                            {intl.formatMessage({ id: "job-modal.form-description" })}
                        </h2>

                        <ApplyJobForm
                            recaptchaRef={recaptchaRef}
                            formDescription={null}
                            selectedJob={selectedJob.title}
                        />
                    </Col>
                </Row>

                <div className="d-flex flex-row justify-content-center">
                    <Button
                        color="secondary"
                        outline
                        className="modal-close-button"
                        onClick={() => onToggle()}
                    >{intl.formatMessage({ id: "close" })}</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}
