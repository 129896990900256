import React from 'react'
import { useIntl } from "gatsby-plugin-intl"
import { Button, Spinner, FormGroup, Input } from "reactstrap"
import { CustomCheckbox, FileInput, CustomFormFeedback } from '../../components/CustomInputs'
import * as validators from '../../utils/validators'

export default ({
    recaptchaRef = null,
    formDescription = null,
    selectedJob = null
}) => {
    const intl = useIntl()

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")
    const [success, setSuccess] = React.useState(false)
    const [name, setName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [cv, setCv] = React.useState(null)
    const [description, setDescription] = React.useState("")
    const [approval, setApproval] = React.useState(false)

    const [validation, setValidation] = React.useState({
        nameStatus: null,
        nameMessage: "",
        emailStatus: null,
        emailMessage: "",
        cvStatus: null,
        cvMessage: "",
        approvalStatus: null,
        approvalMessage: "",
        descriptionStatus: null,
        descriptionMessage: ""
    })

    const onSelectFile = (file) => {
        setCv(file)
    }

    const validateProperites = (properties) => {
        var _properties = Array.isArray(properties) ? properties : [properties]
        var allValid = true
        var validationResponse = {}
        var newValidation = {}

        if(_properties.includes("name")){
            validationResponse = validators.nameValidation(name, intl)
            newValidation = {
                ...newValidation,
                nameStatus: validationResponse.error ? "error": "success",
                nameMessage: validationResponse.message
            }

            if(validationResponse.error){
                allValid = false
            }
        }

        if(_properties.includes("email")){
            validationResponse = validators.emailValidation(email, intl)
            newValidation = {
                ...newValidation,
                emailStatus: validationResponse.error ? "error": "success",
                emailMessage: validationResponse.message
            }

            if(validationResponse.error){
                allValid = false
            }
        }

        if(_properties.includes("cv")){
            validationResponse = validators.cvValidation(cv, intl)
            newValidation = {
                ...newValidation,
                cvStatus: validationResponse.error ? "error": "success",
                cvMessage: validationResponse.message
            }

            if(validationResponse.error){
                allValid = false
            }
        }

        if(_properties.includes("description")){
            validationResponse = validators.cvDescriptionValidation(description, intl)
            newValidation = {
                ...newValidation,
                descriptionStatus: validationResponse.error ? "error": "success",
                descriptionMessage: validationResponse.message
            }

            if(validationResponse.error){
                allValid = false
            }
        }

        if(_properties.includes("approval")){
            validationResponse = validators.approvalValidation(approval, intl)
            newValidation = {
                ...newValidation,
                approvalStatus: validationResponse.error ? "error": "success",
                approvalMessage: validationResponse.message
            }

            if(validationResponse.error){
                allValid = false
            }
        }

        setValidation({ ...validation, ...newValidation })
        return allValid
    }

    const onSendButton = async () => {
        const allValid = validateProperites(["name", "email", "cv", "description", "approval"])
        if(allValid){
            const token = await recaptchaRef.current.executeAsync();
            if(token) {
                setLoading(true)
                setSuccess(false)
                setError(false)

                var formData = new FormData();
                formData.append('type', "job")
                formData.append('email', email)
                formData.append('cv', cv)
                formData.append('description', description)
                formData.append('approval', approval)
                formData.append('g-recaptcha-response', token)
                if(selectedJob){
                    formData.append("job", selectedJob)
                }

                fetch(`${process.env.GATSBY_API_URL}/send.php`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Cache-control': 'no-cache'
                    }
                })
                .then(response => response.json())
                .then(result => {
                    if (result && result.error) {
                        setError(true)
                        setSuccess(false)
                        setErrorMessage(result.error)
                    } else {
                        setError(false)
                        setSuccess(true)
                        setName("")
                        setEmail("")
                        setCv(null)
                        setDescription("")
                        setApproval(false)
                    }

                    setLoading(false)
                })
                .catch(error => {
                    setLoading(false)
                    setError(true)
                    setErrorMessage(intl.formatMessage({ id: "home.sending-cv-error" }))
                });
            }
        }
    }

    return (
        <>
            {formDescription &&
                <p className="modal-custom-description">
                    {formDescription}
                </p>
            }

            <div className="send-cv-modal-form">
                <FormGroup>
                    <Input
                        className="bottom-bordered-input"
                        placeholder={intl.formatMessage({ id: "home.send-cv-modal-name-placeholder" })}
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />

                    {validation.nameStatus === "error" && <span className="form-validation-text validation-error">{validation.nameMessage}</span>}
                </FormGroup>

                <FormGroup>
                    <Input
                        className="bottom-bordered-input"
                        placeholder={intl.formatMessage({ id: "home.send-cv-modal-email-placeholder" })}
                        value={email}
                        type="email"
                        onChange={e => setEmail(e.target.value)}
                    />

                    {validation.emailStatus === "error" && <span className="form-validation-text validation-error">{validation.emailMessage}</span>}
                </FormGroup>

                <FormGroup>
                    <FileInput
                        className="mb-0"
                        file={cv}
                        placeholder={intl.formatMessage({ id: "home.send-cv-modal-description-placeholder" })}
                        onDeleteFile={() => setCv(null)}
                        onSelectFile={(file) => onSelectFile(file)}
                    />

                    {validation.cvStatus === "error" && <span className="form-validation-text validation-error">{validation.cvMessage}</span>}
                </FormGroup>

                <FormGroup>
                    <Input
                        className="bottom-bordered-input"
                        placeholder={intl.formatMessage({ id: "home.send-cv-modal-cv-placeholder" })}
                        value={description}
                        type="textarea"
                        rows="5"
                        onChange={e => setDescription(e.target.value)}
                    />

                    {validation.descriptionStatus === "error" && <span className="form-validation-text validation-error">{validation.descriptionMessage}</span>}
                </FormGroup>

                <FormGroup className="checkbox-group home-send-cv-modal-approval-checkbox-container mb-0">
                    <CustomCheckbox
                        id="send-cv-approval-checkbox"
                        checked={approval}
                        onChange={() => setApproval(!approval)}
                        className={`form-checkbox checkbox-border`}
                    />

                    <span className="form-checkbox-label home-send-cv-modal-approval-text">
                        {intl.formatMessage({ id: "home.send-cv-modal-cv-approval" })}
                        {validation.approvalStatus === "error" &&
                            <>
                                <br />
                                <span className="form-validation-error validation-error">{validation.approvalMessage}</span>
                            </>
                        }
                    </span>
                </FormGroup>

                {success &&
                    <CustomFormFeedback
                        className="mb-3 mt-4"
                        text={intl.formatMessage({ id: "home.sending-cv-success" })}
                        color="success"
                    />
                }

                {error &&
                    <CustomFormFeedback
                        className="mb-3 mt-4"
                        text={errorMessage}
                        color="error"
                    />
                }
            </div>

            <div className="d-flex flex-row justify-content-center">
                <Button
                    color="secondary"
                    className="modal-submit-button"
                    onClick={() => onSendButton()}
                    disabled={loading}
                >
                    {intl.formatMessage({ id: "home.send" })}
                    {loading && <Spinner size="sm" color="white" className="ml-2" /> }
                </Button>
            </div>

            <div className="d-flex flex-row justify-content-center mt-3">
                <p>{intl.formatMessage({ id: "home.send-manual" })}: rekrutacja@codein.pl</p>
            </div>
        </>
    )
}
